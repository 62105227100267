/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.searchbar{
  input {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-inline-start: 5px !important;
    padding-inline-end: 10px !important;
  }
}
@import "./assets//scss/popover.scss";
@import "./assets/scss/custom.scss";

.ads{
  cursor: pointer !important;
}

.noShadow{
  box-shadow: none;
  --box-shadow: none;
}

.transparent{
  --background: transparent !important;
  background: transparent !important;
}

.remove-padding {
  padding: 0;
}

.container-list-qualification.max-cal{
  label.star{
    &::before{
      // content: '';
      text-shadow: 0 0 10px #952 !important;
    }
  }
}

.container-list-qualification{
  width: max-content;
 
  label.star{
    pointer-events: none;
    padding: 5px !important;
    font-size: 1.05rem !important;
  }
}
.container-list-qualification.store{
  width: max-content;
  label.star{
    pointer-events: none;
    padding: 5px !important;
    font-size: 2rem !important;
  }
}

.custom-modal,
.ion-page {
  overflow: visible !important;
  --overflow: visible !important;
}

.custom-modal {
  --backdrop-opacity: 10% !important;
  --max-width: 350px !important;
  backdrop-filter: blur(1px) !important;
  position: absolute;
  --height: fit-content;
}

.popover-filter {
  --min-width: 90% !important;
  overflow: auto;
}

.aviso-modal {
  --max-width: 300px;
  --border-radius: 10px;
  --backdrop-opacity: 0.2 !important;
  --max-height: 500px;
  --box-shadow: 2px 6px 15px 4px rgba(0, 0, 0, 0.3) !important;
}

.aviso-modal .ion-page {
  display: block;
}

.pop-detail{
  --width: 95%;
  --height: 60%;
}


@media screen and (min-width: 768px) {
  .pop-detail{
    --width: 35%;
    --height: 60%;
  }
  
  .pasarela-pago {
    --border-radius: 10px 0px 0px 10px;
    --backdrop-opacity: 0.1;
    backdrop-filter: blur(0.5px) !important;
    --max-width: 30% !important;
    --min-height: 90vh;
    justify-content: right;
  }
}

.modal-blog-comentarios {
  --border-radius: 7px;
  --backdrop-opacity: 0.1;
  overflow: auto;
  // backdrop-filter: blur(.5px) !important;
}

.planModal {
  --border-radius: 7px;
  --backdrop-opacity: 0.1;
  --width: 90%;
  --height: 90%;
  --box-shadow: 4px 4px 4px 0px rgb(165 165 165 / 66%) !important;
}

.popShare {
  font-size: 1rem;
}

.background-content {
  --background: var(--background-content) !important;
  background: var(--background-content) !important;
}

.formCrearProductos {
  --border-radius: 7px;
  --backdrop-opacity: 0.1;
  --width: 90%;
  --height: 90%;
  --box-shadow: 4px 4px 4px 0px rgb(165 165 165 / 66%) !important;
}

@media screen and (min-width: 500px) {
  .popoverSocials {
    --width: 40%;
  }
}

@media screen and (max-width: 500px) {
  .editBlogComponent {
    padding: 0 !important;
  }
  .formCrearProductos {
    --width: 100%;
    --height: 100%;
  }
}

.showStatics {
  --backdrop-opacity: 0.1;
  --box-shadow: 0px 0px 15px rgb(34 34 34 / 66%) !important;
}

.editBlogComponent {
  --border-radius: 7px;
  --backdrop-opacity: 0.1;
  --box-shadow: 4px 4px 4px 0px rgb(165 165 165 / 66%) !important;
  padding: 2rem;
}

.editBlogComponent .ion-page ion-content {
  --padding-top: 2rem;
  --padding-bottom: 2rem;
  --padding-start: 2rem;
  --padding-end: 2rem;
}
table#customers {
  border-collapse: collapse;
  width: 100%;
}

table#customers td,
table#customers th {
  border: 0px solid #ddd;
  padding: 8px;
}

table#customers tr:nth-child(even) {
  background-color: #f4f4f4;
}

table#customers tr:hover {
  background-color: rgba(221, 221, 221, 0.37);
}

table#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--ion-color-primary);
  color: white;
}

.deleteBlogBtnAction {
  color: #f00 !important;
  --color: #f00;
}

.add-anim::ng-deep input[type="number"]::-webkit-inner-spin-button,
.add-anim::ng-deep input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.boldText{
  font-weight: bold;
}

.searchbar {
  --placeholder-color: rgb(143, 140, 140) !important;
  .searchbar-input-container {
    input{
      font-size: 1rem !important;
    }
  }
}
@media screen and (max-width: 576px){
  .searchbar {
    --placeholder-color: rgb(143, 140, 140) !important;
    .searchbar-input-container {
      input{
        font-size: 12px !important;
      }
    }
  }
}
.emergenciesComponent{
  backdrop-filter: blur(2px);
  --width: 25%;
  --border-radius: 10px;
  --backdrop-opacity: 0;
  --backdrop-opacity: 0;
}

@media screen and (max-width: 1330px) {
  .emergenciesComponent{
    --width: 35%;
  }
}

@media screen and (max-width: 950px) {
  .emergenciesComponent{
    --width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .emergenciesComponent{
    --width: 100%;
  }
}




ion-input{
  caret-color: #000 !important;
}

.input-form::part(icon) {
  transform: translate3d(0px, 0px, 0px);
}

.input-form.select-expanded::part(icon) {
  transform: translate3d(0px, 0px, 0px) rotate(180deg);
}
.btn-yellow{
  --background: linear-gradient(to right, #fcef7f, var(--ion-color-yellow));
  --box-shadow: 0 3px 5px rgba(0,0,0,.2);
  color: #000 !important;
  font-weight: bold;
  text-transform: none;
  --padding-top: 1.4rem;
  --padding-start: 1.7rem;
  --padding-bottom: 1.4rem;
  --padding-end: 1.7rem;
  ion-label{
    text-transform: none;
    color: #000;
  }
}

.btn-gray{
  color: #000 !important;
  font-weight: bold;
  text-transform: none;
  --background: #d4d4d4;
  --box-shadow: 0 3px 5px rgba(0,0,0,.2);
  --padding-top: 1.4rem;
  --padding-start: 1.7rem;
  --padding-bottom: 1.4rem;
  --padding-end: 1.7rem;
  ion-label{
    text-transform: none;
    color: #000;
  }
}

.modalDetail{
  --width: 90%;
  --height: 80%;
  --border-radius: 2rem;
  --backdrop-opacity: .1;
  --box-shadow: 0 0 8px rgb(0 0 0 / 18%);
  backdrop-filter: blur(1px);
}

.currency{
  --background: transparent;
  --box-shadow: none;
  --backdrop-opacity: .05;
  --height: 100%
}

.popMulti{
  --min-width: 30%;
}

.popOfert{
  --min-width: 40%
}

.postulant{
  --border-radius: 1.2rem;
  --backdrop-opacity: .05;
}

.successComponent{
  --width: 90%;
  --height: 30%;
  --border-radius: 1.2rem;
}

@media screen and (min-width: 769px){
  .successComponent{
    --width: 35%;
    --height: 35%;
    --border-radius: 1.2rem;
  }
}