.h-100 {
  min-height: 100vh;
  --min-height: 100vh;
}
.w-100 {
  min-width: 100vw;
}
.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.logo-50 {
  width: 50%;
  max-width: 20rem;
}
.logo-30 {
  width: 30%;
  max-width: 12.5rem;
}
.bg-primary {
  --background: var(--ion-color-primary);
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}
