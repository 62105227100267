/** Google **/
:root {
  --ion-color-google: #ff0000;
  --ion-color-google-rgb: 255, 0, 0;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255, 255, 255;
  --ion-color-google-shade: #e00000;
  --ion-color-google-tint: #ff1a1a;
}
.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

/** Facebook **/
:root {
  --ion-color-facebook: #0000ff;
  --ion-color-facebook-rgb: 0, 0, 255;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #0000e0;
  --ion-color-facebook-tint: #1a1aff;
}
.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

/** apple **/
:root {
  --ion-color-apple: #ffffff;
  --ion-color-apple-rgb: 255, 255, 255;
  --ion-color-apple-contrast: #000000;
  --ion-color-apple-contrast-rgb: 0, 0, 0;
  --ion-color-apple-shade: #e0e0e0;
  --ion-color-apple-tint: #ffffff;
}
.ion-color-apple {
  --ion-color-base: var(--ion-color-apple);
  --ion-color-base-rgb: var(--ion-color-apple-rgb);
  --ion-color-contrast: var(--ion-color-apple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-apple-contrast-rgb);
  --ion-color-shade: var(--ion-color-apple-shade);
  --ion-color-tint: var(--ion-color-apple-tint);
}
