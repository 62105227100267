// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/* Font size */
:root {
  --ion-font-family: "Poppins", sans-serif;
}
@import "./socialMediaColors.scss";

/** Ionic CSS Variables **/
:root {
  /** primary **/ /** Naranja **/
  --ion-color-yellow: #ffdc00;
  --ion-color-primary: #1c1a1a;
  --ion-color-primary-rgb: 247, 167, 62;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #d99337;
  --ion-color-primary-tint: #f8b051;

  /** secondary **/ /** Caramelo **/
  --ion-color-secondary: #272727;
  --ion-color-secondary-rgb: 198, 107, 33;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ae5e1d;
  --ion-color-secondary-tint: #cc7a37;

  /** tertiary **/ /** Marron **/
  --ion-color-tertiary: #707070;
  --ion-color-tertiary-rgb: 102, 67, 38;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5a3b21;
  --ion-color-tertiary-tint: #75563c;

  /** success **/ /** Naranja Palido **/
  --ion-color-success: #ffdc00;
  --ion-color-success-rgb: 255, 226, 156;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #e0c789;
  --ion-color-success-tint: #ffe5a6;

  /** warning **/ /** Naranja Brillante **/
  --ion-color-warning: #ff9100;
  --ion-color-warning-rgb: 255, 145, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e08000;
  --ion-color-warning-tint: #ff9c1a;

  /** danger **/ /** Naranja Intenso **/
  --ion-color-danger: #ff7e16;
  --ion-color-danger-rgb: 255, 126, 22;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e06f13;
  --ion-color-danger-tint: #ff8b2d;

  /** dark **/ /** Gris Oscuro **/
  --ion-color-dark: #292726;
  --ion-color-dark-rgb: 41, 39, 38;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #242221;
  --ion-color-dark-tint: #3e3d3c;

  /** medium **/ /** Gris Plomo **/
  --ion-color-medium: #d4d4d4;
  --ion-color-medium-rgb: 112, 112, 112;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #636363;
  --ion-color-medium-tint: #7e7e7e;

  /** light **/ /** Blanco Trafico **/
  --ion-color-light: #f9f9f9;
  --ion-color-light-rgb: 249, 249, 249;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dbdbdb;
  --ion-color-light-tint: #fafafa;

}

/***********************
*** Customize Colors ***
***********************/

/** Gris Claro **/
:root {
  --ion-color-gris-claro: #c6c6c6;
  --ion-color-gris-claro-rgb: 198, 198, 198;
  --ion-color-gris-claro-contrast: #000000;
  --ion-color-gris-claro-contrast-rgb: 0, 0, 0;
  --ion-color-gris-claro-shade: #aeaeae;
  --ion-color-gris-claro-tint: #cccccc;
  --background-content: rgba(241, 241, 241, 0.582);
}

.ion-color-gris-claro {
  --ion-color-base: var(--ion-color-gris-claro);
  --ion-color-base-rgb: var(--ion-color-gris-claro-rgb);
  --ion-color-contrast: var(--ion-color-gris-claro-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gris-claro-contrast-rgb);
  --ion-color-shade: var(--ion-color-gris-claro-shade);
  --ion-color-tint: var(--ion-color-gris-claro-tint);
}

/** Gris Palido **/
:root {
  --ion-color-gris-palido: #e2e2e2;
  --ion-color-gris-palido-rgb: 226, 226, 226;
  --ion-color-gris-palido-contrast: #000000;
  --ion-color-gris-palido-contrast-rgb: 0, 0, 0;
  --ion-color-gris-palido-shade: #c7c7c7;
  --ion-color-gris-palido-tint: #e5e5e5;
}
.ion-color-gris-palido {
  --ion-color-base: var(--ion-color-gris-palido);
  --ion-color-base-rgb: var(--ion-color-gris-palido-rgb);
  --ion-color-contrast: var(--ion-color-gris-palido-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gris-palido-contrast-rgb);
  --ion-color-shade: var(--ion-color-gris-palido-shade);
  --ion-color-tint: var(--ion-color-gris-palido-tint);
}

:root {
  /** letra textContacto en home **/
  --ion-color-text1: #361e12;
  --ion-color-text2: #d4d4d4;
  --ion-color-text3: #32302f;
  --ion-color-text4: #ececec;

  /** colores redes sociales **/
  --ion-color-button: #3a5897;
  --ion-color-button2: #00bb2d;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-content: rgba(241, 241, 241, 0.582);
  }
}
// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */
//   input, .native-input {
//     color: #000000 !important;
//     --color: #000000 !important;
//   }
//   body {
//     /** primary **/ /** Naranja **/
//     --ion-color-primary: #f7a73e;
//     --ion-color-primary-rgb: 247, 167, 62;
//     --ion-color-primary-contrast: #000000;
//     --ion-color-primary-contrast-rgb: 0, 0, 0;
//     --ion-color-primary-shade: #d99337;
//     --ion-color-primary-tint: #f8b051;

//     /** secondary **/ /** Caramelo **/
//     --ion-color-secondary: #c66b21;
//     --ion-color-secondary-rgb: 198, 107, 33;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #ae5e1d;
//     --ion-color-secondary-tint: #cc7a37;

//     /** tertiary **/ /** Marron **/
//     --ion-color-tertiary: #664326;
//     --ion-color-tertiary-rgb: 102, 67, 38;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//     --ion-color-tertiary-shade: #5a3b21;
//     --ion-color-tertiary-tint: #75563c;

//     /** success **/ /** Naranja Palido **/
//     --ion-color-success: #ffe29c;
//     --ion-color-success-rgb: 255, 226, 156;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0, 0, 0;
//     --ion-color-success-shade: #e0c789;
//     --ion-color-success-tint: #ffe5a6;

//     /** warning **/ /** Naranja Brillante **/
//     --ion-color-warning: #ff9100;
//     --ion-color-warning-rgb: 255, 145, 0;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0, 0, 0;
//     --ion-color-warning-shade: #e08000;
//     --ion-color-warning-tint: #ff9c1a;

//     /** danger **/ /** Naranja Intenso **/
//     --ion-color-danger: #ff7e16;
//     --ion-color-danger-rgb: 255, 126, 22;
//     --ion-color-danger-contrast: #000000;
//     --ion-color-danger-contrast-rgb: 0, 0, 0;
//     --ion-color-danger-shade: #e06f13;
//     --ion-color-danger-tint: #ff8b2d;

//     /** dark **/ /** Gris Oscuro **/
//     --ion-color-dark: #292726;
//     --ion-color-dark-rgb: 41, 39, 38;
//     --ion-color-dark-contrast: #ffffff;
//     --ion-color-dark-contrast-rgb: 255, 255, 255;
//     --ion-color-dark-shade: #242221;
//     --ion-color-dark-tint: #3e3d3c;

//     /** medium **/ /** Gris Plomo **/
//     --ion-color-medium: #707070;
//     --ion-color-medium-rgb: 112, 112, 112;
//     --ion-color-medium-contrast: #ffffff;
//     --ion-color-medium-contrast-rgb: 255, 255, 255;
//     --ion-color-medium-shade: #636363;
//     --ion-color-medium-tint: #7e7e7e;

//     /** light **/ /** Blanco Trafico **/
//     --ion-color-light: #f9f9f9;
//     --ion-color-light-rgb: 249, 249, 249;
//     --ion-color-light-contrast: #000000;
//     --ion-color-light-contrast-rgb: 0, 0, 0;
//     --ion-color-light-shade: #dbdbdb;
//     --ion-color-light-tint: #fafafa;

//     // letra textContacto en home--
//     --ion-color-text1: #361e12;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #000000;
//     --ion-background-color-rgb: 0, 0, 0;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #000000;

//     --ion-card-background: #1c1c1d;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }

//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18, 18, 18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }
