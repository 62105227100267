.popoverClass {
  --width: 90%;
}

@media (min-width: 576px) {
  .popoverClass {
    --width: 75%;
  }
}
.popoverRecovery {
  --width: 30%;
  ion-button {
    margin: auto;
    width: 80%;
  }
}

@media (max-width: 576px) {
  .popoverRecovery {
    --width: 90%;
  }
}
